.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 5, 50, 0.316);
  
}

.modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: rgb(247, 247, 249);
  width: 55vw;
  height: 68vh;
  border-radius: 10px;
  outline: none;
  background-color: #1F2A40;
}
.sellmodal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: rgb(247, 247, 249);
  width: 36vw;
  height: 60vh;

  max-height: 65vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  outline: none;
  background-color: #1F2A40;
}
.sellmodal_content {
  overflow-y: auto; /* Enable vertical scrolling for content */
  flex: 1; /* Take up remaining space and allow scrolling */
}

.form{
  // background-color:darkblue;
  margin:2%;
  .label{
    color:black;
  }
}
.sellform{
  // background-color:darkblue;
  margin:2.5%;
  .label{
    color:black;
  }
}